import React from "react";
import { Flex, Box, Logo, Button, Text } from "@11fsfoundry/figloo";
import { useAuth0 } from "@auth0/auth0-react";

function Login() {
  const { loginWithRedirect, isLoading } = useAuth0();
  if (isLoading) {
    return <Text p={3}>Loading</Text>;
  }
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg="gsSlate"
      width={1}
      height="100%"
      flexDirection="column"
    >
      <Box mb={3}>
        <Logo type="light" />
      </Box>
      <Box>
        <Button onClick={() => loginWithRedirect()} flex={1} variant="primary">
          Sign in
        </Button>
      </Box>
    </Flex>
  );
}

export default Login;
