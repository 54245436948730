import React from "react";
import { toast as Toaster } from "react-toastify";
import { IconAlert, IconCheck, Text, Flex } from "@11fsfoundry/figloo";

export const notifySuccess = (message: string) => {
  Toaster.success(
    <Flex>
      <IconCheck />
      <Text m="auto" variant="default" color="white">
        {message}
      </Text>
    </Flex>
  );
};

export const notifyError = (message: string) => {
  Toaster.error(
    <Flex>
      <IconAlert />
      <Text m="auto" variant="default" color="white">
        {message}
      </Text>
    </Flex>
  );
};
