import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Box, Button, Flex, Input, Text } from "@11fsfoundry/figloo";
import {
  useSandboxUsersQuery,
  useGenerateTokenMutation,
  GenerateTokenMutation,
  Exact,
  useListExternalUserFactsLazyQuery,
  ListExternalUserFactsDocument,
  useSetUserFactsMutation,
} from "../types-and-hooks";
import { MutationFunctionOptions } from "@apollo/client";
import CopyToClipboard from "react-copy-to-clipboard";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

interface CustomerRowProps {
  username: string;
  auth0UserId: string;
  externalUserId: string;
  generateApiUserToken: (
    options?:
      | MutationFunctionOptions<
          GenerateTokenMutation,
          Exact<{
            auth0UserId: string;
          }>
        >
      | undefined
  ) => Promise<any>;
  API: string;
}

interface ModalProps {
  userToken: string | undefined;
  setModal: React.Dispatch<React.SetStateAction<Boolean>>;
}

const Modal = ({ userToken, setModal }: ModalProps) => {
  const [copied, setCopied] = useState(false);
  return (
    <Flex
      style={{ position: "fixed", zIndex: 1, top: 0, left: 0 }}
      height="100vh"
      width="100vw"
      bg="rgba(0, 0, 0, 0.7)"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Flex
        bg="white"
        p={4}
        maxWidth="60%"
        flexDirection="row"
        flexWrap="wrap"
        style={{ position: "relative" }}
      >
        <Text
          sx={{ ":hover": { cursor: "pointer" } }}
          onClick={() => setModal(false)}
          color="black"
          style={{ position: "absolute", top: "10px", right: "20px" }}
        >
          X
        </Text>
        <Text variant="h2" mb={2} width="100%">
          Api Token
        </Text>
        <Flex
          overflowY="hidden"
          overflowX="scroll"
          p={1}
          style={{
            border: "1px solid #d4d4d4",
            position: "relative",
          }}
          width="90%"
        >
          <Text
            data-testid="tokenElement"
            display="flex"
            fontFamily="monospace"
            style={{ whiteSpace: "nowrap" }}
          >
            {userToken}
          </Text>
        </Flex>
        <CopyToClipboard
          text={userToken as string}
          onCopy={() => {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 2000);
          }}
        >
          <Button width="10%" height="33px" variant="primary">
            {copied ? "Copied!" : "Copy"}
          </Button>
        </CopyToClipboard>
      </Flex>
    </Flex>
  );
};

const CustomerRow = ({
  username,
  auth0UserId,
  externalUserId,
  generateApiUserToken,
  API,
}: CustomerRowProps) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  return (
    <Flex bg="white" flex={1} p={2} mb={2} alignItems="center">
      <Flex maxWidth="40%" flex={1}>
        <Text>{username}</Text>
      </Flex>
      <Flex maxWidth="40%" flex={1}>
        <Text>{externalUserId}</Text>
      </Flex>
      <Flex maxWidth="20%" flex={1} justifyContent="flex-end">
        <Button
          width="195px"
          loading={loading}
          onClick={async () => {
            setLoading(true);
            try {
              await generateApiUserToken({
                variables: { auth0UserId },
              });
            } catch (err) {
              alert(
                "There was an error generating the token for this user, please try again."
              );
            }
            setLoading(false);
          }}
        >
          Generate API token
        </Button>
        <Button
          ml={2}
          variant="secondary"
          width="195px"
          loading={loading}
          onClick={() => {
            if (API === "consumer")
              history.push(`/api-explorer/${API}/${auth0UserId}`);
            else
              history.push(`/api-explorer/${API}`);
          }}
        >
          Use in API Explorer
        </Button>
      </Flex>
    </Flex>
  );
};

function UsersScreen() {
  const [modal, setModal] = useState<Boolean>(false);
  const [userToken, setUserToken] = useState<string | undefined>(undefined);
  const { data, error, loading } = useSandboxUsersQuery();
  const [generateApiUserToken] = useGenerateTokenMutation({
    onCompleted: (data) => {
      setModal(true);
      setUserToken(data.generateConsumerApiToken);
    },
  });
  const [externalUserId, setExternalUserId] = useState("");
  const [factName, setFactName] = useState("");
  const [factValue, setFactValue] = useState("");
  const handleError = useCallback((err) => {
    window.alert(err);
  }, []);
  const [
    listExternaUserFacts,
    { data: factData, client },
  ] = useListExternalUserFactsLazyQuery({
    onError: handleError,
  });
  const handleUpdate = useCallback(() => {
    // hacky, hopefully in the future the mutation will return the facts
    const facts = factData?.externalUser.facts
      .filter((fact) => {
        // from this tool we only modify facts with source "demo_tools"
        if (fact.source !== "demo_tools") {
          return true;
        }
        return fact.factName !== factName;
      })
      .concat({
        factName,
        factValue,
        source: "demo_tools",
        createdAt: new Date().toISOString(),
      });
    client?.writeQuery({
      query: ListExternalUserFactsDocument,
      variables: {
        externalUserId,
      },
      data: {
        externalUser: {
          ...factData?.externalUser,
          facts,
        },
      },
    });
    setFactName("");
    setFactValue("");
  }, [client, factData, externalUserId, factName, factValue]);
  const [
    setUserFacts,
    { loading: loadingSetUserFacts },
  ] = useSetUserFactsMutation({
    onError: handleError,
    onCompleted: handleUpdate,
  });

  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [selfIdToken, setSelfIdToken] = useState<IdToken>();

  useEffect(() => {
    getIdTokenClaims().then((idToken) => setSelfIdToken(idToken));
  }, [getIdTokenClaims]);

  const customerRowHeader = (
    <Flex
      px={4}
      flexDirection="row"
      flex={1}
      mb={2}
      justifyContent="flex-start"
    >
      <Flex width="40%">
        <Text style={{ fontWeight: "bold" }}>Username</Text>
      </Flex>
      <Flex width="40%">
        <Text style={{ fontWeight: "bold" }}>Foundry External User Id</Text>
      </Flex>
    </Flex>
  );

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex flexDirection="column" flex={1} mb={4}>
        <Text variant="h1" pb={1}>
          Users
        </Text>
        <Text variant="p" pb={3}>
          View and generate tokens for a pre generated list of{" "}
          <a
            href="https://docs.dev.foundry.11fs.com/concepts#users"
            target="_blank"
            rel="noreferrer noopener"
          >
            Foundry users
          </a>
        </Text>
        <Text mb={3} variant="h2">
          Administrator (Me)
        </Text>
        {customerRowHeader}
        {selfIdToken && (
          <CustomerRow
            key={selfIdToken.sub}
            username={selfIdToken.name || ""}
            auth0UserId={selfIdToken.sub}
            externalUserId={"foundry:identity:user:auth0:" + selfIdToken.sub}
            generateApiUserToken={() =>
              getAccessTokenSilently().then((token) => {
                setModal(true);
                setUserToken(token);
              })
            }
            API="admin"
          />
        )}
        <Text mb={3} variant="h2">
          Available Customer Logins
        </Text>
        {customerRowHeader}
        {error && <Text color="red">Error: {error.message}</Text>}
        {loading && <Text>Loading...</Text>}
        {data?.consumerApiUsers.map((user) => (
          <CustomerRow
            key={user.id}
            username={user.username}
            auth0UserId={user.id}
            externalUserId={user.foundryExternalId}
            generateApiUserToken={generateApiUserToken}
            API={user.consumerApiEnabled ? "consumer" : "admin"}
          />
        ))}
        {modal && <Modal userToken={userToken} setModal={setModal} />}
        <Text variant="h2" mb={1}>
          Set User Facts
        </Text>
        <Flex maxWidth={800} flexDirection="row" mb={3}>
          <Input
            placeholder="Foundry External User Id, e.g. foundry:identity:user:auth0:auth0|5e6b5f4d76c8536728529454"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const id = e.target.value;
              setExternalUserId(id);
            }}
            value={externalUserId}
          />
          <Button
            loading={loading}
            onClick={() =>
              listExternaUserFacts({
                variables: {
                  externalUserId,
                },
              })
            }
            variant="primary"
          >
            Load
          </Button>
        </Flex>
        {factData?.externalUser && (
          <Box mb={3}>
            <Text fontSize={2} mb={3}>
              User facts
            </Text>
            {factData?.externalUser.facts.map((fact) => (
              <Box key={`${fact.source}_${fact.factName}`} mb={2}>
                <Flex flexDirection="row">
                  <Text mr={1}>
                    {fact.factName}: {fact.factValue}
                  </Text>
                  <Text fontSize={0} color="copyThree">
                    ({fact.source})
                  </Text>
                </Flex>
              </Box>
            ))}
          </Box>
        )}
        {factData?.externalUser && (
          <Box
            maxWidth={300}
            p={2}
            sx={{ borderColor: "gsRat", borderWidth: 1, borderStyle: "solid" }}
          >
            <Text fontSize={2} mb={2}>
              Add new user fact
            </Text>
            <Text mb={1}>Fact name</Text>
            <Input
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const id = e.target.value;
                setFactName(id);
              }}
              value={factName}
              mb={2}
            />
            <Text mb={1}>Fact value</Text>
            <Input
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const id = e.target.value;
                setFactValue(id);
              }}
              value={factValue}
              mb={2}
            />
            <Button
              loading={loadingSetUserFacts}
              onClick={() =>
                setUserFacts({
                  variables: {
                    externalUserId,
                    facts: [
                      {
                        factName,
                        factValue,
                        source: "demo_tools",
                      },
                    ],
                  },
                })
              }
            >
              Add fact
            </Button>
          </Box>
        )}
      </Flex>
    </Flex>
  );
}

export default UsersScreen;
