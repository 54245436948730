const CARD_ACCEPTANCE_METHODS = [
    "CONTACTLESS_CARD",
    "CONTACTLESS_DEVICE",
    "CHIP",
    "ECOMMERCE",
    "MAIL",
    "PHONE"
  ];
  
  export default CARD_ACCEPTANCE_METHODS;
  