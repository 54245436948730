import React, { ChangeEvent, useState } from "react";
import {
  Text,
  Flex,
  Box,
  Button,
  Spinner,
  IconBin,
  Input,
} from "@11fsfoundry/figloo";
import { notifyError, notifySuccess } from "../components/Toast";
import {
  useListProductsQuery,
  ListProductsDocument,
  useHideProductMutation,
  useCreateNewProductMutation,
  ListProductsQuery,
  useAddAttributesMutation,
  useAddProductNoteMutation,
  useSubmitProductForReviewMutation,
  useApproveProductVersionMutation,
  useOpenProductApplicationsMutation,
  NoteVisibility,
  OpenProductApplicationsMutation,
} from "../types-and-hooks";
import { useAuth0 } from "@auth0/auth0-react";

enum ProductStatus {
  DRAFT = "DRAFT",
  REJECTED = "REJECTED",
  UNDER_REVIEW = "UNDER_REVIEW",
  APPROVED = "APPROVED",
  APPLICATIONS_OPEN = "APPLICATIONS_OPEN",
  APPLICATIONS_PAUSED = "APPLICATIONS_PAUSED",
  ARCHIVED = "ARCHIVED",
}

interface ModalProps {
  productName: string;
  setProductName: React.Dispatch<React.SetStateAction<undefined | string>>;
  productDescription: string;
  setProductDescription: React.Dispatch<
    React.SetStateAction<undefined | string>
  >;
  setModal: React.Dispatch<React.SetStateAction<Boolean>>;
  createProduct: () => Promise<any>;
  createProductError: any;
  createproductData: OpenProductApplicationsMutation;
  createProductLoading: boolean;
}

const {
  REACT_APP_DEFAULT_PRODUCT_ASSET: productAsset,
  REACT_APP_DEFAULT_PRODUCT_PAYMENT_PROVIDER: cardProviderSelect,
} = process.env;

const Modal = ({
  productName,
  setProductName,
  productDescription,
  setProductDescription,
  setModal,
  createProduct,
  createproductData,
  createProductError,
  createProductLoading,
}: ModalProps) => {
  //TODO - make this reusable
  return (
    <Flex
      style={{ position: "fixed", zIndex: 1, top: 0, left: 0 }}
      height="100vh"
      width="100vw"
      bg="rgba(0, 0, 0, 0.7)"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      data-testid="Modal"
    >
      <Flex
        bg="white"
        p={4}
        maxWidth="60%"
        flexDirection="column"
        flexWrap="wrap"
        style={{ position: "relative" }}
      >
        <Text
          sx={{ ":hover": { cursor: "pointer" } }}
          onClick={() => {
            setModal(false);
            setProductName(undefined);
            setProductDescription(undefined);
          }}
          color="black"
          style={{ position: "absolute", top: "10px", right: "20px" }}
        >
          X
        </Text>
        <Text mb={1} variant="h2" width="100%">
          Create a Product
        </Text>
        <Text variant="p" mb={2}>
          Enter a name and description for your Product. This Product will be
          approved and set live ready for you to use immediately.
        </Text>
        <Flex width={400} flexDirection="column">
          <Text mb={1}>Name</Text>
          <Input
            data-testid="nameInput"
            value={productName}
            placeholder="e.g Debit card"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const name = e.target.value;
              setProductName(name);
            }}
            mb={2}
          />
          <Text mb={1}>Description</Text>
          <Input
            data-testid="descriptionInput"
            value={productDescription}
            placeholder="e.g A pre paid card for x"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const description = e.target.value;
              setProductDescription(description);
            }}
            mt={1}
          />
          <Button
            data-testid="CreateProductButton"
            loading={createProductLoading}
            onClick={() => {
              if (productName && productDescription) {
                return createProduct();
              }
              alert("Product needs a name and description");
            }}
            my={4}
          >
            Create
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

function Products() {
  const [modal, setModal] = useState<Boolean>(false);
  const [productName, setProductName] = useState<undefined | string>(undefined);
  const [productDescription, setProductDescription] = useState<
    undefined | string
  >(undefined);
  const [createProductloading, setCreateProductLoading] = useState(false);
  const { loading, error, data, client } = useListProductsQuery();
  const [hideProduct] = useHideProductMutation({
    onCompleted: (d) => {
      const deletedProductId = d.hideProduct;
      client.writeQuery({
        query: ListProductsDocument,
        data: {
          products:
            data && data.products.filter((p) => p.id !== deletedProductId),
        },
      });
    },
    onError: () => window.alert("Error, try again or refresh page"),
  });

  const [createNewProduct] = useCreateNewProductMutation();

  const [addAttributes] = useAddAttributesMutation();
  const [addProductNote] = useAddProductNoteMutation();
  const [submitProductForReview] = useSubmitProductForReviewMutation();
  const [approveProductVersion] = useApproveProductVersionMutation();
  const [
    openProductApplications,
    { error: createProductError, data: createproductData },
  ] = useOpenProductApplicationsMutation({
    update: (cache, { data }) => {
      const newProductFromResponse = data?.openProductApplications;
      const existingProducts = cache.readQuery<ListProductsQuery>({
        query: ListProductsDocument,
      });
      if (existingProducts && newProductFromResponse) {
        cache.writeQuery({
          query: ListProductsDocument,
          data: {
            products: [newProductFromResponse, ...existingProducts.products],
          },
        });
      }
    },
    onCompleted: () => {
      setProductName(undefined);
      setProductDescription(undefined);
      setModal(false);
      notifySuccess("Product created!");
    },
  });
  const { user } = useAuth0();

  const createProduct = async () => {
    setCreateProductLoading(true);
    try {
      const createdProduct = await createNewProduct({
        variables: {
          name: productName as string,
          description: productDescription as string,
        },
      });
      const { data } = createdProduct;
      const productVersion = {
        productId: data?.createNewProduct.id as string,
        versionNumber: data?.createNewProduct.versions[0].number as number,
      };
      await addAttributes({
        variables: {
          ...productVersion,
          attributes: JSON.stringify([{ productAsset }, { cardProviderSelect }]),
        },
      });
      await addProductNote({
        variables: {
          note: {
            name: "product_category",
            value: "demo-workflow",
            visibility: NoteVisibility.Public,
          },
          ...productVersion,
        },
      });
      await submitProductForReview({
        variables: {
          ...productVersion,
          reviewerId: `foundry:identity:user:auth0:${user.sub}`,
        },
      });
      await approveProductVersion({
        variables: productVersion,
      });
      await openProductApplications({
        variables: productVersion,
      });
    } catch (err) {
      notifyError("There was an error creating the product");
    }
    setCreateProductLoading(false);
  };

  const getStatusColor = (status: ProductStatus) => {
    if (status === ProductStatus.APPLICATIONS_OPEN) return "mint";
    return "#d86161";
  };

  return (
    <Flex flexDirection="column" flex={1}>
      <Text data-testid="ProductsTitle" variant="h1" pb={1}>
        Products
      </Text>
      <Text variant="p" pb={3}>
        View and create{" "}
        <a
          href="https://docs.dev.foundry.11fs.com/concepts#products"
          target="_blank"
          rel="noreferrer noopener"
        >
          Products
        </a>{" "}
        within Foundry
      </Text>
      <Text my={2} variant="h2">
        Existing Products
      </Text>
      {loading && (
        <Box>
          <Text mb={1} variant="default">
            Loading products
          </Text>
          <Spinner />
        </Box>
      )}
      {data && data.products && (
        <Box>
          <Flex mb={2} flexDirection="column" alignItems="flex-start">
            {data.products.map((product) => (
              <Flex key={product.id} flexDirection="row" mb={1} bg="gsSmoke">
                <Flex
                  onClick={() =>
                    hideProduct({
                      variables: {
                        productId: product.id,
                      },
                    })
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                  alignItems="center"
                  p={1}
                  bg="gsRat"
                >
                  <IconBin />
                </Flex>
                <Box p={1}>
                  <Text ml={1}>{product.name}</Text>
                </Box>
                <Box
                  mr={1}
                  alignSelf="center"
                  backgroundColor={getStatusColor(
                    product?.versions[0].status as ProductStatus
                  )}
                  height="10px"
                  width="10px"
                  style={{ borderRadius: "50%" }}
                ></Box>
              </Flex>
            ))}
          </Flex>
          <Button
            loading={loading}
            onClick={() => {
              setModal(true);
            }}
            my={4}
          >
            Create Product
          </Button>
        </Box>
      )}
      {error && (
        <Text variant="default" color="red">
          Error: {error.message}
        </Text>
      )}
      {modal && (
        <Modal
          setModal={setModal}
          productName={productName as string}
          setProductName={setProductName}
          productDescription={productDescription as string}
          setProductDescription={setProductDescription}
          createProduct={createProduct}
          createproductData={
            createproductData as OpenProductApplicationsMutation
          }
          createProductError={createProductError}
          createProductLoading={createProductloading}
        />
      )}
    </Flex>
  );
}

export default Products;
