import React, { useState, ChangeEvent } from "react";
import {
  Input,
  CheckboxField,
  Text,
  Flex,
  Box,
  Select,
  Button,
  Spinner,
} from "@11fsfoundry/figloo";
import CURRENCIES from "../currencies";
import CARD_ACCEPTANCE_METHODS from "../cardAcceptanceMethods";
import {
  useCreatePpsTransactionMutation,
  useClearPpsTransactionMutation,
  useCreatePpsRefundMutation,
  useCreatePpsFasterPaymentCreditMutation,
  useClearPpsFasterPaymentCreditMutation,
  useCreateFakePaymentsFasterPaymentCreditMutation,
  useAuthoriseFakePaymentsTransactionMutation,
  useCaptureFakePaymentsTransactionMutation
} from "../types-and-hooks";
import { useEffect } from "react";
import { ApolloError } from "@apollo/client";

const { v4: uuid } = require('uuid');

enum TransactionType {
  CARD_PAYMENT = "CARD_PAYMENT",
  FASTER_PAYMENT_IN = "FASTER_PAYMENT_IN",
  CARD_REFUND = "CARD_REFUND",
}

enum PaymentType {
  PPS = "PPS",
  FAKE_PAYMENTS = "FAKE_PAYMENTS",
}

function CardTransaction() {
  const [transactionSuccess, setTransactionSuccess] = useState<
    undefined | boolean
  >(undefined);
  const [transactionFail, setTransactionFail] = useState<undefined | string>(
    undefined
  );
  const [transactionType, setTransactionType] = useState<
    undefined | TransactionType
  >(undefined);
  const [paymentType, setPaymentType] = useState<
    undefined | PaymentType
  >(undefined);
  const [accountNumber, setAccountNumber] = useState("");
  const [payerName, setPayerName] = useState("JOE BLOGGS");
  const [payerSortCode, setPayerSortCode] = useState("12-34-56");
  const [payerAccountNumber, setPayerAccountNumber] = useState("12345678");
  const [reference, setReference] = useState("");
  const [ppsCardSerial, setPpsCardSerial] = useState("");
  const [merchantName, setMerchantName] = useState("");
  const [mcc, setMcc] = useState("5999");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("GBP");
  const [transactionId, setTransactionId] = useState<any>("");
  const [acceptanceMethod, setAcceptanceMethod] = useState<any>(CARD_ACCEPTANCE_METHODS[0]);

  const mutationParams = {
    onError: (error: ApolloError) => {
      setTransactionSuccess(undefined);
      setTransactionFail(error.message);
    },
    onCompleted: () => {
      setTransactionSuccess(true);
      setTransactionFail(undefined);
    },
  };

  const [createPpsFasterPayment] = useCreatePpsFasterPaymentCreditMutation(mutationParams);
  const [createPpsCardTransaction, { loading }] = useCreatePpsTransactionMutation(mutationParams);
  const [createPpsCardRefund] = useCreatePpsRefundMutation(mutationParams);
  const [clearPpsFasterPayment] = useClearPpsFasterPaymentCreditMutation(mutationParams);
  const [clearPpsTransaction] = useClearPpsTransactionMutation(mutationParams);
  
  const [createFakePaymentsFasterPayment] = useCreateFakePaymentsFasterPaymentCreditMutation(mutationParams);
  const [authoriseFakePaymentsCardTransaction] = useAuthoriseFakePaymentsTransactionMutation(mutationParams)
  const [captureFakePaymentsCardTransaction] = useCaptureFakePaymentsTransactionMutation(mutationParams)
  const [createFakePaymentsCardRefund] = useCaptureFakePaymentsTransactionMutation(mutationParams)

  useEffect(() => {
    // Hide notification after 4 seconds
    if (transactionSuccess)
      setTimeout(() => {
        setTransactionSuccess(false);
      }, 4000);
  }, [transactionSuccess]);

  useEffect(() => {
    if (transactionFail)
      setTimeout(() => {
        setTransactionFail(undefined);
      }, 4000);
  });

  const validFields = () => {
    if (transactionType === TransactionType.FASTER_PAYMENT_IN && paymentType === PaymentType.PPS) {
      if (!accountNumber || !payerName || !payerSortCode || !payerAccountNumber || !currency || !amount) {
        return false;
      }
    } else if (transactionType === TransactionType.FASTER_PAYMENT_IN && paymentType === PaymentType.FAKE_PAYMENTS) {
      if (!accountNumber || !payerName || !payerSortCode || !payerAccountNumber || !currency || !amount || !reference) {
        return false;
      }
    } else if (paymentType === PaymentType.PPS && (transactionType === TransactionType.CARD_PAYMENT || transactionType === TransactionType.CARD_REFUND)) {
      if (!accountNumber || !ppsCardSerial || !merchantName || !mcc || !acceptanceMethod || !currency || !amount ) {
        return false;
      }
    } else if (paymentType === PaymentType.FAKE_PAYMENTS && (transactionType === TransactionType.CARD_PAYMENT || transactionType === TransactionType.CARD_REFUND)) {
      if (!accountNumber || !merchantName || !mcc || !acceptanceMethod || !currency || !amount ) {
        return false;
     }
    }
    return true;
  };

  function acceptanceMethodTokenToLabel(acceptanceMethodToken: string) {
    if (acceptanceMethodToken === "CONTACTLESS_CARD") {
      return "CONTACTLESS CARD"
    } else if (acceptanceMethodToken === "CONTACTLESS_DEVICE") {
      return "CONTACTLESS DEVICE"
    } else {
      return acceptanceMethodToken
    }
  }

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex flexDirection="column" mb={2}>
        <Text variant="h1" pb={1}>
          Transactions
        </Text>
        <Text variant="p" pb={3}>
          This tool will allow you to mock{" "}
          <a
            href="https://docs.dev.foundry.11fs.com/concepts#transactions"
            target="_blank"
            rel="noreferrer noopener"
          >
            transactions
          </a>{" "}
          coming from an external payment provider (PPS or Fake Payments).
        </Text>
        <Box my={2} width={400}>
          <Select
          style={{ flex: 1 }}
          mb={2}
          value={paymentType}
          onChange={({ target }: ChangeEvent<HTMLSelectElement>) => {
            setPaymentType(target.value as PaymentType)
            setTransactionType(undefined)
          }
          }
          placeholder="Select a payment type"
          defaultValue="Choose a payment provider..."
          >
            <option value={undefined} disabled> Choose a payment provider...</option>
            <option value={PaymentType.PPS}>PPS</option>
            <option value={PaymentType.FAKE_PAYMENTS}>Fake Payments</option>
          </Select>
          {paymentType === PaymentType.FAKE_PAYMENTS && (
            <>
            <Select
            style={{ flex: 1 }}
            value={transactionType}
            onChange={({ target }: ChangeEvent<HTMLSelectElement>) =>
              setTransactionType(target.value as TransactionType)
            }
            placeholder="Select a transaction type"
            defaultValue="Choose a transaction type..."
            >
              <option value={undefined} disabled> Choose a transaction type...</option>
              <option value={TransactionType.FASTER_PAYMENT_IN}>Faster Payment In</option>
              <option value={TransactionType.CARD_PAYMENT}>Card Payment</option>
              <option value={TransactionType.CARD_REFUND}>Card Refund</option>
            </Select>
            </>
            )}
          {paymentType === PaymentType.PPS && (
            <Select
            style={{ flex: 1 }}
            value={transactionType}
            onChange={({ target }: ChangeEvent<HTMLSelectElement>) =>
              setTransactionType(target.value as TransactionType)
            }
            placeholder="Select a transaction type"
            defaultValue="Choose a transaction type..."
            >
              <option value={undefined} disabled> Choose a transaction type...</option>
              <option value={TransactionType.FASTER_PAYMENT_IN}>Faster Payment In</option>
              <option value={TransactionType.CARD_PAYMENT}>Card Payment</option>
              <option value={TransactionType.CARD_REFUND}>Card Refund</option>
            </Select>
          )}
          {transactionType !== undefined && (
            <Box my={2}>
              {paymentType === PaymentType.PPS && (
                <>
                <Text mb={1}>PPS Account Number</Text>
                <Flex flexDirection="row">
                  <Input
                    placeholder="e.g. 50189516410467"
                    disabled={loading}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const id = e.target.value;
                      setAccountNumber(id);
                    }}
                    value={accountNumber}
                  />
                </Flex>
                </>
              )}
              {paymentType === PaymentType.FAKE_PAYMENTS && (
                <>
                <Text mb={1}>Foundry Account ID</Text>
                <Flex flexDirection="row">
                  <Input
                    placeholder="e.g. foundry:product:account:foundry:123-456-789"
                    disabled={loading}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const id = e.target.value;
                      setAccountNumber(id);
                    }}
                    value={accountNumber}
                  />
                </Flex>
                </>
              )}
            </Box>
          )}
          {transactionType === TransactionType.FASTER_PAYMENT_IN && paymentType === PaymentType.FAKE_PAYMENTS && (
            <>
              <Box mb={2}>
                <Text mb={1}>Source Account Name</Text>
                <Input
                  disabled={loading}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPayerName(e.target.value)
                  }
                  value={payerName}
                />
              </Box>
              <Box mb={2}>
                <Text mb={1}>Source Account Sort Code</Text>
                <Input
                  disabled={loading}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPayerSortCode(e.target.value)
                  }
                  value={payerSortCode}
                />
              </Box>
              <Box mb={2}>
                <Text mb={1}>Source Account Account Number</Text>
                <Input
                  disabled={loading}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPayerAccountNumber(e.target.value)
                  }
                  value={payerAccountNumber}
                />
              </Box>
              <Box mb={2}>
                <Text mb={1}>Reference</Text>
                <Input
                  disabled={loading}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setReference(e.target.value)
                  }
                  value={reference}
                />
              </Box>
            </>
          )}
          {transactionType === TransactionType.FASTER_PAYMENT_IN && paymentType === PaymentType.PPS && (
            <>
              <Box mb={2}>
                <Text mb={1}>Payer Name</Text>
                <Input
                  disabled={loading}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPayerName(e.target.value)
                  }
                  value={payerName}
                />
              </Box>
              <Box mb={2}>
                <Text mb={1}>Payer Sort Code</Text>
                <Input
                  disabled={loading}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPayerSortCode(e.target.value)
                  }
                  value={payerSortCode}
                />
              </Box>
              <Box mb={2}>
                <Text mb={1}>Payer Account Number</Text>
                <Input
                  disabled={loading}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPayerAccountNumber(e.target.value)
                  }
                  value={payerAccountNumber}
                />
              </Box>
            </>
          )}
          {paymentType === PaymentType.PPS && (transactionType === TransactionType.CARD_PAYMENT || transactionType === TransactionType.CARD_REFUND) ? (
            <Box mb={2}>
              <Text mb={1}>PPS Card Serial</Text>
              <Flex flexDirection="row">
                <Input
                  placeholder="e.g. 8699814709"
                  disabled={loading}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const serial = e.target.value;
                    setPpsCardSerial(serial);
                  }}
                  value={ppsCardSerial}
                />
              </Flex>
            </Box>
          ) : null}
          {transactionType === TransactionType.CARD_PAYMENT || transactionType === TransactionType.CARD_REFUND ? (
            <Box mb={2}>
              <Text mb={1}>Merchant Name</Text>
              <Input
                disabled={loading}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setMerchantName(e.target.value)
                }
                value={merchantName}
              />
            </Box>
          ) : null}
          {transactionType === TransactionType.CARD_PAYMENT || transactionType === TransactionType.CARD_REFUND ? (
            <Box mb={2}>
              <Text mb={1}>MCC</Text>
              <Input
                disabled={loading}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setMcc(e.target.value)
                }
                value={mcc}
              />
            </Box>
          ) : null}
          {transactionType === TransactionType.CARD_PAYMENT || transactionType === TransactionType.CARD_REFUND ? (
            <Flex mb={2} flexDirection="row">
            <Box mr={1}>
              <Text mb={1}>Acceptance Method</Text>
              <Select
                value={acceptanceMethod}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => 
                  setAcceptanceMethod(e.target.value)
                }
              >
                {CARD_ACCEPTANCE_METHODS.map((cardAcceptanceMethod) => (
                  <option key={cardAcceptanceMethod} value={cardAcceptanceMethod}>
                    {acceptanceMethodTokenToLabel(cardAcceptanceMethod)}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
          ) : null}
          {transactionType !== undefined && (
            <Flex mb={2} flexDirection="row">
              <Box mr={1}>
                <Text mb={1}>Currency</Text>
                <Select
                  value={currency}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setCurrency(e.target.value)
                  }
                >
                  {CURRENCIES.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box flex={1}>
                <Text mb={1}>Amount</Text>
                <Input
                  disabled={loading}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAmount(e.target.value)
                  }
                  value={amount}
                />
              </Box>
            </Flex>
          )}          
          {transactionType !== undefined && (
            <Flex
              my={4}
              flexDirection="row"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Button
                disabled={loading || !validFields()}
                variant="primary"
                onClick={() => {
                  const currentTimestamp = new Date().toISOString();
                  if (paymentType === PaymentType.PPS && transactionType === TransactionType.CARD_PAYMENT && validFields()) {
                    createPpsCardTransaction({
                      variables: {
                        input: {
                          timestamp: currentTimestamp,
                          ppsAccountNumber: accountNumber,
                          ppsCardSerial,
                          merchantName,
                          amount: parseFloat(amount) * 100,
                          currency,
                          mcc,
                          acceptanceMethod
                        },
                      },
                    }).then((res) => setTransactionId(res.data?.createPpsTransaction.transactionId))
                      .catch(() => {});
                  }
                  if (paymentType === PaymentType.FAKE_PAYMENTS && transactionType === TransactionType.CARD_PAYMENT && validFields()) {
                    authoriseFakePaymentsCardTransaction({
                      variables: {
                        input: {
                          txnId: uuid().replace(/-/g, "").slice(0,20),
                          txnType: "REDEMPTION",
                          amount: parseFloat(amount),
                          currency,
                          accountId: accountNumber,
                          createdDateTime: currentTimestamp,
                          merchantName,
                          merchantCategory: mcc,
                          facts: [{factName: "acceptance-method", factValue: acceptanceMethod, source: "DEMO TOOLS"}]
                        },
                      },
                    }).then((res) => setTransactionId(res.data?.authoriseFakePaymentsTransaction.transactionId))
                      .catch(() => {});             
                  }
                  if (paymentType == PaymentType.PPS && transactionType === TransactionType.CARD_REFUND && validFields()) {
                    createPpsCardRefund({
                      variables: {
                        input: {
                          timestamp: currentTimestamp,
                          ppsAccountNumber: accountNumber,
                          ppsCardSerial,
                          merchantName,
                          mcc,
                          amount: parseFloat(amount) * 100,
                          currency,
                        },
                      },
                    }).catch(() => {});
                  }
                  if (paymentType === PaymentType.FAKE_PAYMENTS && transactionType === TransactionType.CARD_REFUND && validFields()) {
                    createFakePaymentsCardRefund({
                      variables: {
                        input: {
                          txnId: uuid().replace(/-/g, "").slice(0,20),
                          txnType: "REFUND",
                          amount: parseFloat(amount),
                          currency,
                          accountId: accountNumber,
                          createdDateTime: currentTimestamp,
                          merchantName,
                          merchantCategory: mcc,
                          facts: [{factName: "acceptance-method", factValue: acceptanceMethod, source: "DEMO TOOLS"}]
                        },
                      },
                    }).catch(() => {});
                  }
                  if (transactionType === TransactionType.FASTER_PAYMENT_IN && paymentType === PaymentType.PPS && validFields()) {
                    createPpsFasterPayment({
                      variables: {
                        input: {
                          timestamp: currentTimestamp,
                          ppsAccountNumber: accountNumber,
                          payerName,
                          payerSortCode: payerSortCode.replace(/-/g, ""),
                          payerAccountNumber,
                          amount: parseFloat(amount) * 100,
                          currency,
                        },
                      },
                    }).then((res) => setTransactionId(res.data?.createPpsFasterPaymentCredit.transactionId))
                      .catch(() => {});
                  }
                  if (transactionType === TransactionType.FASTER_PAYMENT_IN && paymentType === PaymentType.FAKE_PAYMENTS && validFields()) {
                    createFakePaymentsFasterPayment({
                      variables: {
                        input: {
                          accountId: accountNumber,
                          payerName,
                          payerSortCode: payerSortCode.replace(/-/g, ""),
                          payerAccountNumber,
                          reference,
                          amount: parseFloat(amount),
                          currency,
                        },
                      },
                    }).catch(() => {});
                  }
                }}
              >
                1. Create transaction
              </Button>
              {transactionType !== TransactionType.CARD_REFUND && (
                <Button
                  disabled={transactionId.length === 0}
                  variant="primary"
                  onClick={() => {
                    const currentTimestamp = new Date().toISOString();
                    if (paymentType === PaymentType.PPS && transactionType === TransactionType.CARD_PAYMENT) {
                      clearPpsTransaction({
                        variables: {
                          input: {
                            transactionId,
                            timestamp: currentTimestamp,
                            ppsCardSerial,
                            ppsAccountNumber: accountNumber,
                            merchantName,
                            amount: parseFloat(amount) * 100,
                            currency,
                            mcc,
                            acceptanceMethod
                          },
                        },
                      });
                    }
                    if (paymentType === PaymentType.FAKE_PAYMENTS && transactionType === TransactionType.CARD_PAYMENT && validFields()) {
                      captureFakePaymentsCardTransaction({
                        variables: {
                          input: {
                            txnId: transactionId,
                            txnType: "REDEMPTION",
                            amount: parseFloat(amount),
                            currency,
                            accountId: accountNumber,
                            createdDateTime: currentTimestamp,
                            merchantName,
                            merchantCategory: mcc,
                            facts: [{factName: "acceptance-method", factValue: acceptanceMethod, source: "DEMO TOOLS"}]
                          },
                        },
                      });
                    }
                    if (transactionType === TransactionType.FASTER_PAYMENT_IN && paymentType === PaymentType.PPS) {
                      clearPpsFasterPayment({
                        variables: {
                          input: {
                            transactionId,
                            timestamp: currentTimestamp,
                            ppsAccountNumber: accountNumber,
                            payerName,
                            payerSortCode: payerSortCode.replace(/-/g, ""),
                            payerAccountNumber,
                            amount: parseFloat(amount) * 100,
                            currency,
                          },
                        }
                      });
                    }
                  }}
                >
                  2. Clear transaction
                </Button>
              )}
              {transactionSuccess && (
                <Text my={2} variant="default" color="green">
                  Transaction successful{" "}
                  <span role="img" aria-label="thumb-up">
                    👍
                  </span>
                </Text>
              )}
              {transactionFail && (
                <Text my={2} variant="default" color="red">
                  Transaction did not go through! Reason: {transactionFail}
                </Text>
              )}
            </Flex>
          )}
        </Box>
      </Flex>
      {(loading) && <Spinner />}
    </Flex>
  );
}

export default CardTransaction;
