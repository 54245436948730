import React from "react";
import { ProductApplicationQuery } from "../../../types-and-hooks";
import StepContainer from "../StepContainer";
import { Text, Box } from "@11fsfoundry/figloo";

interface NextStepsProps {
  nextSteps: ProductApplicationQuery["productApplication"]["nextSteps"];
}

function NextSteps({ nextSteps }: NextStepsProps) {
  return (
    <>
      {nextSteps.map((data) => {
        if (
          data.__typename === "GenerateRepaymentScheduleFutureWorkflowStepData"
        ) {
          return (
            <StepContainer key={data.step} name={data.step}>
              <Box mt={3}>
                <Text>Generate repayment schedule</Text>
              </Box>
            </StepContainer>
          );
        }
        if (data.__typename === "SystemInputFutureWorkflowStepData") {
          return (
            <StepContainer key={data.step} name={data.step}>
              <Box mt={3}>
                <Text>Fetch 3rd party systems info</Text>
              </Box>
            </StepContainer>
          );
        }
        if (data.__typename === "UserFactsFutureWorkflowStepData") {
          return (
            <StepContainer key={data.step} name={data.step}>
              <Box mt={3}>
                <Text>Fetch user facts</Text>
              </Box>
            </StepContainer>
          );
        }
        if (data.__typename === "UserInputFutureWorkflowStepData") {
          return (
            <StepContainer key={data.step} name={data.step}>
              <Box mt={3}>
                <Text>Ask user input</Text>
              </Box>
            </StepContainer>
          );
        }
        // unknown step type
        return null;
      })}
    </>
  );
}

export default NextSteps;
