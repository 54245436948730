import React from "react";
import { Box, Text } from "@11fsfoundry/figloo";
import { UserFactsProcessedWorkflowStepData } from "../../../types-and-hooks";

interface UserFactsProcessedWorkflowStepDataProps {
  data: UserFactsProcessedWorkflowStepData;
}

function UserFactsProcessedWorkflowStepDataStep({
  data,
}: UserFactsProcessedWorkflowStepDataProps) {
  return (
    <Box mt={3}>
      {data.userFacts.length > 0 && (
        <Box mb={3}>
          <Text mb={2} variant="h3">
            User Facts
          </Text>
          {data.userFacts.map((attr) => (
            <Text mb={1}>
              {attr.name}: {attr.value}
            </Text>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default UserFactsProcessedWorkflowStepDataStep;
