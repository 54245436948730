import React from "react";
import { Text, Flex, Box, Spinner, Button } from "@11fsfoundry/figloo";
import { useProductApplicationQuery } from "../../types-and-hooks";
import CurrentStep from "./CurrentStep";
import PreviousSteps from "./PreviousSteps";
import NextSteps from "./NextSteps";
import { useParams, useHistory } from "react-router-dom";

function ProductApplication() {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { loading, error, data } = useProductApplicationQuery({
    variables: {
      productApplicationId: params.id,
    },
    pollInterval: 1000,
    fetchPolicy: "network-only",
  });
  return (
    <Flex flexDirection="column" flex={1}>
      <Flex flexDirection="row" justifyContent="space-between">
        <Text mb={3} variant="h3">
          {params.id}
        </Text>
      </Flex>
      {loading && (
        <Box>
          <Text mb={1} variant="default">
            Loading product application...
          </Text>
          <Spinner />
        </Box>
      )}
      {error && (
        <Text mb={2} variant="default" color="red">
          {error.message}
        </Text>
      )}
      {data && data.productApplication && (
        <Box>
          <Flex flexDirection="row" justifyContent="space-between">
            <Box>
              <Text mb={1} variant="default">
                Product name
              </Text>
              <Text variant="h2">{data.productApplication.product.name}</Text>
            </Box>
            <Box>
              <Text textAlign="right" mb={1} variant="default">
                State
              </Text>
              <Text textAlign="right" variant="h2">
                {data.productApplication.state}
              </Text>
            </Box>
          </Flex>
          <PreviousSteps
            previousSteps={data.productApplication.previousSteps}
          />
          <CurrentStep
            data={data.productApplication.currentStep}
            state={data.productApplication.state}
            id={data.productApplication.id}
          />
          <NextSteps nextSteps={data.productApplication.nextSteps} />
        </Box>
      )}
    </Flex>
  );
}

export default ProductApplication;
