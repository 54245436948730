import React, { ReactElement } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import introspectionQueryResultData from "./fragmentTypes.json";
import { ThemeProvider } from "emotion-theming";
import { theme, Box } from "@11fsfoundry/figloo";
import {
  ApolloProvider,
  InMemoryCache,
  ApolloClient,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import NavHeader from "./components/NavHeader";
import Transactions from "./screens/Transactions";
import ProductApplicationScreen from "./screens/ProductApplicationScreen";
import ProductApplicationsScreen from "./screens/ProductApplicationsScreen";
import ProductsScreen from "./screens/ProductsScreen";
import LoginScreen from "./screens/LoginScreen";
import UsersScreen from "./screens/UsersScreen";
import DocsScreen from "./screens/DocsScreen";
import ApiExplorerScreen from "./screens/ApiExplorerScreen";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/toaster-theme.css";

const cache = new InMemoryCache({
  possibleTypes: introspectionQueryResultData.possibleTypes,
});

const GRAPHQL_URL =
  process.env.REACT_APP_GRAPHQL_SERVER || "http://0.0.0.0:4000/";

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

function Routes() {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <Route path="/" component={LoginScreen} />;
  }

  return (
    <Box height="100%">
      <NavHeader />
      <Box bg="#fafafa" p={4}>
        <Route exact path="/">
          <Redirect to="/docs" />
        </Route>
        <Route exact path="/docs" component={DocsScreen} />
        <Route
          exact
          path="/api-explorer/:apiParam?/:auth0Id?"
          component={ApiExplorerScreen}
        />
        <Route exact path="/users" component={UsersScreen} />
        <Route exact path="/products" component={ProductsScreen} />
        <Route exact path="/transactions" component={Transactions} />
        <Route
          exact
          path="/product-applications"
          component={ProductApplicationsScreen}
        />
        <Route
          exact
          path="/product-applications/:id"
          component={ProductApplicationScreen}
        />
      </Box>
    </Box>
  );
}

const GraphQLProvider = ({ children }: { children: ReactElement }) => {
  const { getAccessTokenSilently } = useAuth0();

  const authLink = setContext((_, { headers }) => {
    return getAccessTokenSilently().then(token => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });
  });
  const apolloClient = new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
  });
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

function App() {
  return (
    <Router>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
        redirectUri={window.location.origin}
      >
        <GraphQLProvider>
          <ThemeProvider theme={theme}>
            <ToastContainer
              autoClose={2000}
              position="top-center"
              hideProgressBar
              closeButton={false}
            />
            <Routes />
          </ThemeProvider>
        </GraphQLProvider>
      </Auth0Provider>
    </Router>
  );
}

export default App;
