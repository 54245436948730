import React, { useState, ChangeEvent } from "react";
import {
  Box,
  Flex,
  Text,
  theme,
  Select,
  Button,
  Input,
} from "@11fsfoundry/figloo";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import {
  ProductApplicationQuery,
  ProductApplicationState,
  useUpdateProductApplicationFactsMutation,
} from "../../types-and-hooks";

interface CurrentStepProps {
  data: ProductApplicationQuery["productApplication"]["currentStep"];
  state: ProductApplicationState;
  id: string;
}

function getStateColor(state: ProductApplicationState) {
  if (state === ProductApplicationState.Rejected) {
    return "cherry";
  }
  if (state === ProductApplicationState.Failed) {
    return "cherry";
  }
  return theme.colors.mintNeon;
}

function CurrentStep({ data, state, id }: CurrentStepProps) {
  const [isOpen, setIsOpen] = useState(true);
  const [attributeValue, setAttributeValue] = useState("");
  const [attributeName, setAttributeName] = useState("");
  const [attributeSource, setAttributeSource] = useState("");
  const [
    updateProductApplicationFacts,
    { loading },
  ] = useUpdateProductApplicationFactsMutation({
    onError: (err) => window.alert(err.message),
  });
  if (!data) {
    return null;
  }
  return (
    <Box
      bg="#fff"
      p={2}
      my={2}
      sx={{
        boxShadow: `0 0.063em 0.313em 0 rgba(0,0,0,.07), 0 0.438em 1.063em 0 rgba(0,0,0,.1)`,
        borderColor: getStateColor(state),
        borderWidth: 2,
        borderStyle: "solid",
        position: "relative",
      }}
    >
      <Box
        bg={getStateColor(state)}
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          width: 75,
          height: 20,
        }}
      >
        <Text textAlign="center" fontSize={0}>
          CURRENT
        </Text>
      </Box>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsOpen(!isOpen)}
        sx={{ cursor: "pointer" }}
      >
        <Flex alignItems="center" justifyContent="center">
          {isOpen ? <FaCaretDown size={18} /> : <FaCaretRight size={18} />}
        </Flex>
        <Text mx={2} flex={1}>
          {data.step}
        </Text>
      </Flex>
      {isOpen && (
        <Box mt={3}>
          {data.__typename === "UserInputCurrentWorkflowStepData" && (
            <Flex flexDirection="row" mb={3} mx={-3}>
              {data.providedAttributes.length > 0 && (
                <Box mx={3}>
                  <Text mb={2} variant="h3">
                    Provided Attributes
                  </Text>
                  {data.providedAttributes.map((attr) => (
                    <Text mb={1}>
                      {attr.name}: {attr.value}
                    </Text>
                  ))}
                </Box>
              )}
              {data.missingAttributes.length > 0 && (
                <Box mx={3}>
                  <Text mb={2} variant="h3">
                    Missing Attributes
                  </Text>
                  {data.missingAttributes.map((attr) => (
                    <Text mb={1}>{attr.id}</Text>
                  ))}
                </Box>
              )}
            </Flex>
          )}
          {data.__typename === "UserFactsCurrentWorkflowStepData" && (
            <Flex flexDirection="row" mb={3} mx={-3}>
              {data.userFacts.length > 0 && (
                <Box mx={3}>
                  <Text mb={2} variant="h3">
                    User Facts
                  </Text>
                  {data.userFacts.map((attr) => (
                    <Text mb={1}>
                      {attr.name}: {attr.value}
                    </Text>
                  ))}
                </Box>
              )}
              {data.missingAttributes.length > 0 && (
                <Box mx={3}>
                  <Text mb={2} variant="h3">
                    Missing Attributes
                  </Text>
                  {data.missingAttributes.map((attr) => (
                    <Text mb={1}>{attr.name}</Text>
                  ))}
                </Box>
              )}
            </Flex>
          )}
          {data.__typename === "SystemInputCurrentWorkflowStepData" && (
            <Flex flexDirection="row" mb={3} mx={-3}>
              {data.systemAttributes.length > 0 && (
                <Box mx={3}>
                  <Text mb={2} variant="h3">
                    System Attributes
                  </Text>
                  {data.systemAttributes.map((attr) => (
                    <Text mb={1}>
                      {attr.name}: {attr.value}
                    </Text>
                  ))}
                </Box>
              )}
              {data.missingAttributes.length > 0 && (
                <Box mx={3}>
                  <Text mb={2} variant="h3">
                    Missing Attributes
                  </Text>
                  {data.missingAttributes.map((attr) => (
                    <Text mb={1}>{attr.name}</Text>
                  ))}
                </Box>
              )}
              <Box maxWidth={300} mx={3}>
                <Text mb={1}>Select missing attribute to add</Text>
                <Select
                  mb={3}
                  value={attributeName}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    const attrName = e.target.value;
                    const source = data.missingAttributes.find(
                      (a) => a.name === attrName
                    )?.source;
                    setAttributeName(attrName);
                    setAttributeSource(source || "");
                  }}
                >
                  <option disabled value=""></option>
                  {data.missingAttributes.map((attribute) => (
                    <option value={attribute.name}>{attribute.name}</option>
                  ))}
                </Select>
                <Text mb={1}>Value</Text>
                <Input
                  mb={3}
                  value={attributeValue}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAttributeValue(e.target.value)
                  }
                />
                <Button
                  loading={loading}
                  onClick={() => {
                    if (!attributeValue) {
                      window.alert("Attribute value cannot be empty");
                      return;
                    }
                    updateProductApplicationFacts({
                      variables: {
                        productApplicationId: id,
                        facts: [
                          {
                            name: attributeName,
                            value: attributeValue,
                            source: attributeSource,
                          },
                        ],
                      },
                    });
                    setAttributeValue("");
                    setAttributeName("");
                    setAttributeSource("");
                  }}
                >
                  Save attribute
                </Button>
              </Box>
            </Flex>
          )}
          {data.__typename ===
            "GenerateRepaymentScheduleCurrentWorkflowStepData" && (
            <Text>Creating repayment schedule</Text>
          )}
          {data.rejectionReasons && data.rejectionReasons[0] && (
            <Box mx={3}>
              <Flex mb={1}>
                <Text mr={2}>
                  {`Rule name: ${data.rejectionReasons[0].ruleName}`}
                </Text>
                <Text>
                  {`Reason: ${data.rejectionReasons[0].rejectionDescription}`}
                </Text>
              </Flex>
              <Text as="pre" sx={{ whiteSpace: "pre-wrap" }}>
                {
                  data.rejectionReasons[0].matchedConditions.split(
                    " Determined"
                  )[0]
                }
              </Text>
            </Box>
          )}
          {data.failureReasons && data.failureReasons.length > 0 && (
            <Box>
              <Flex mb={1}>
                <Text mr={2}>Failure reason:</Text>
                {data.failureReasons.map((reason) => {
                  return (
                    <Text key={reason.failureDescription}>
                      {reason.failureDescription}
                    </Text>
                  );
                })}
              </Flex>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default CurrentStep;
