import React, { useState, ReactNode } from "react";
import { Box, Flex, Text } from "@11fsfoundry/figloo";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";

interface StepContainerProps {
  children?: ReactNode;
  name: string;
}

function StepContainer({ children, name }: StepContainerProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box
      bg="#fff"
      p={2}
      my={2}
      sx={{
        boxShadow: `0 0.063em 0.313em 0 rgba(0,0,0,.07), 0 0.438em 1.063em 0 rgba(0,0,0,.1)`,
        borderColor: "transparent",
        borderWidth: 2,
        borderStyle: "solid",
      }}
    >
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsOpen(!isOpen)}
        sx={{ cursor: "pointer" }}
      >
        <Flex alignItems="center" justifyContent="center">
          {isOpen ? <FaCaretDown size={18} /> : <FaCaretRight size={18} />}
        </Flex>
        <Text mx={2} flex={1}>
          {name}
        </Text>
      </Flex>
      {isOpen && children}
    </Box>
  );
}

export default StepContainer;
