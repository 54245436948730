import React from "react";
import { ProductApplicationQuery } from "../../../types-and-hooks";
import StepContainer from "../StepContainer";
import UserInputProcessedWorkflowStepData from "./UserInputProcessedWorkflowStepData";
import UserFactsProcessedWorkflowStepData from "./UserFactsProcessedWorkflowStepData";
import GenerateRepaymentScheduleProcessedWorkflowStepData from "./GenerateRepaymentScheduleProcessedWorkflowStepData";
import SystemInputProcessedWorkflowStepData from "./SystemInputProcessedWorkflowStepData";

interface PreviousStepsProps {
  previousSteps: ProductApplicationQuery["productApplication"]["previousSteps"];
}

function PreviousSteps({ previousSteps }: PreviousStepsProps) {
  return (
    <>
      {previousSteps.map((data) => {
        if (data.__typename === "UserInputProcessedWorkflowStepData") {
          return (
            <StepContainer key={data.step} name={data.step}>
              <UserInputProcessedWorkflowStepData data={data} />
            </StepContainer>
          );
        }
        if (data.__typename === "UserFactsProcessedWorkflowStepData") {
          return (
            <StepContainer key={data.step} name={data.step}>
              <UserFactsProcessedWorkflowStepData data={data} />
            </StepContainer>
          );
        }
        if (data.__typename === "SystemInputProcessedWorkflowStepData") {
          return (
            <StepContainer key={data.step} name={data.step}>
              <SystemInputProcessedWorkflowStepData data={data} />
            </StepContainer>
          );
        }
        if (
          data.__typename ===
          "GenerateRepaymentScheduleProcessedWorkflowStepData"
        ) {
          return (
            <StepContainer key={data.step} name={data.step}>
              <GenerateRepaymentScheduleProcessedWorkflowStepData />
            </StepContainer>
          );
        }
        // unknown step type
        return null;
      })}
    </>
  );
}

export default PreviousSteps;
