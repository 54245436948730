import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Flex, Box, Logo, Link, theme } from "@11fsfoundry/figloo";
import { useAuth0 } from "@auth0/auth0-react";

function NavHeader() {
  const location = useLocation();
  const { logout } = useAuth0();
  return (
    <Flex bg="gsSlate" height={80}>
      <Box m="auto" width={1} px={4}>
        <Flex alignItems="center">
          <Flex alignItems="center" mr={4}>
            <Logo type="solo-light" />
          </Flex>
          <Flex>
            <Link
              as={NavLink}
              variant="navLink"
              to="/docs"
              sx={{
                color:
                  location.pathname === "/docs"
                    ? theme.colors.mintNeon
                    : theme.colors.white,
              }}
            >
              Documentation
            </Link>
            <Link
              as={NavLink}
              variant="navLink"
              to="/api-explorer"
              sx={{
                color: location.pathname.includes("/api-explorer")
                  ? theme.colors.mintNeon
                  : theme.colors.white,
              }}
            >
              API Explorer
            </Link>
            <Link
              as={NavLink}
              variant="navLink"
              to="/users"
              sx={{
                color:
                  location.pathname === "/users"
                    ? theme.colors.mintNeon
                    : theme.colors.white,
              }}
            >
              Users
            </Link>
            <Link
              as={NavLink}
              variant="navLink"
              to="/products"
              sx={{
                color:
                  location.pathname === "/products"
                    ? theme.colors.mintNeon
                    : theme.colors.white,
              }}
            >
              Products
            </Link>
            <Link
              as={NavLink}
              variant="navLink"
              to="/product-applications"
              sx={{
                color: location.pathname.includes("/product-applications")
                  ? theme.colors.mintNeon
                  : theme.colors.white,
              }}
            >
              Product Applications
            </Link>
            <Link
              as={NavLink}
              variant="navLink"
              to="/transactions"
              sx={{
                color:
                  location.pathname === "/transactions"
                    ? theme.colors.mintNeon
                    : theme.colors.white,
              }}
            >
              Transactions
            </Link>
          </Flex>
          <Box mx="auto" />
          <Flex alignItems="center">
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => logout({ returnTo: window.location.origin })}
              variant="navLink"
            >
              Logout
            </Link>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}

export default NavHeader;
