import { Box, Flex, Text, SelectWithValidation } from "@11fsfoundry/figloo";
import React, {ChangeEvent, useMemo} from "react";
import { GraphiQL, Fetcher, FetcherOpts, FetcherParams } from "graphiql";
import { createGraphiQLFetcher } from "@graphiql/toolkit";
import "../../node_modules/graphiql/graphiql.css";
import { useHistory, useParams } from "react-router-dom";
import {useGenerateTokenMutation, useSandboxUsersQuery} from "../types-and-hooks";
import { useAuth0 } from "@auth0/auth0-react";

const adminFetcher = createGraphiQLFetcher({
  url: process.env.REACT_APP_ADMIN_GRAPHQL_URL as string,
});

const consumerFetcher = createGraphiQLFetcher({
  url: process.env.REACT_APP_CONSUMER_GRAPHQL_URL as string,
});

enum API {
  ADMIN = "admin",
  CONSUMER = "consumer",
}

function ApiExplorerScreen() {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const { apiParam, auth0Id } = useParams<any>();
  const [generateApiUserToken] = useGenerateTokenMutation();
  const { data } = useSandboxUsersQuery();

  const updateUser = (api: API | undefined, user: string | undefined) => {
    if (!api)
      history.replace("/api-explorer");
    else if (api === API.ADMIN || !user)
      history.replace(`/api-explorer/${api}`);
    else
      history.replace(`/api-explorer/${api}/${user}`);
  };

  // validated version of apiParam in case of nonsense URL
  const api: API | undefined = apiParam === API.ADMIN || apiParam === API.CONSUMER ? apiParam : undefined;

  // consumer API userId, defaulting to first user if not set
  const userId: string | undefined = auth0Id || (data && data.consumerApiUsers[0]?.id);

  const fetcher = useMemo<Fetcher | undefined>(() => {
    if (api === API.ADMIN || (api === API.CONSUMER && userId)) {
      const tokenPromise = api === API.ADMIN ? getAccessTokenSilently() :
          userId ? generateApiUserToken({variables: {auth0UserId: userId}}).then(({data, errors}) => data ? Promise.resolve(data.generateConsumerApiToken) : Promise.reject(errors)) :
              Promise.reject('no user');

      const backend = api === API.ADMIN ? adminFetcher : consumerFetcher;

      return (graphQLParams: FetcherParams, opts?: FetcherOpts) => {
          return tokenPromise.then(token => {
              return backend(graphQLParams, {...opts, headers: {'Authorization': `Bearer ${token}`}});
          }).catch(reason => ({errors: reason}));
      };
    }
  }, [api, userId, generateApiUserToken, getAccessTokenSilently]);

  return (
    <Flex flexDirection="column" flex={1} height="100vh">
      <Text variant="h1" pb={1}>
        API Explorer
      </Text>
      <Text variant="p" pb={1}>
        Use the API Explorer to make calls against Foundry's{" "}
        <a
          href="https://docs.dev.foundry.11fs.com/api-overviews"
          target="_blank"
          rel="noreferrer noopener"
        >
          Admin and Consumer API
        </a>
        .
      </Text>
      <Flex flexDirection="row" pb={1}>
      <Box mr={10} width={400}>
        <Text>API:</Text>
        <SelectWithValidation
          data-testid="apiSelect"
          style={{ flex: 1 }}
          value={api || "Choose an API to explore..."}
          onChange={({ target }: ChangeEvent<HTMLSelectElement>) =>
            updateUser(target.value as API, userId)
          }
        >
          <option value={undefined} disabled>
            Choose an API to explore...
          </option>
          <option value={API.ADMIN}>Admin API</option>
          <option value={API.CONSUMER}>Consumer API</option>
        </SelectWithValidation>
      </Box>
      { api === API.CONSUMER &&
      <Box width={400}>
        <Text>Customer logon to use:</Text>
        <SelectWithValidation
          data-testid="userSelect"
          style={{ flex: 1 }}
          value={userId}
          onChange={({ target }: ChangeEvent<HTMLSelectElement>) =>
              updateUser(API.CONSUMER, target.value)
          }
        >
        {
          data ? data.consumerApiUsers.map(u => <option key={u.id} value={u.id}>{u.username}</option>) : []
        }
        </SelectWithValidation>
      </Box>
      }
      </Flex>
      <Box minHeight="100vh">
        {fetcher && (
          <GraphiQL
            data-testid="graphiQL"
            defaultQuery="{ ping }"
            fetcher={fetcher}
            response=""
          />
        )}
      </Box>
    </Flex>
  );
}
export default ApiExplorerScreen;
