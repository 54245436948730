import React from "react";
import { Flex } from "@11fsfoundry/figloo";

function DocsScreen() {
  return (
    <Flex flexDirection="column" flex={1} height="calc(100vh - 150px)">
      <Flex flexDirection="column" flex={1}>
        <iframe
          style={{ border: "none" }}
          height="100%"
          width="100%"
          title="docs"
          src="https://docs.int.demo-bank.foundry-demo.io/"
        />
      </Flex>
    </Flex>
  );
}

export default DocsScreen;
