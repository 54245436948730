const CURRENCIES = [
  "GBP",
  "EUR",
  "NOK",
  "USD",
  "AED",
  "AUD",
  "BGN",
  "BRL",
  "CAD",
  "CHF",
  "CZK",
  "DKK",
  "HKD",
  "ILS",
  "INR",
  "JPY",
  "KRW",
  "MXN",
  "NZD",
  "PLN",
  "QAR",
  "RON",
  "RUB",
  "SAR",
  "SEK",
  "SGD",
  "THB",
  "TRY",
  "ZAR",
];

export default CURRENCIES;
