import React from "react";
import { Box, Text } from "@11fsfoundry/figloo";
import { SystemInputProcessedWorkflowStepData } from "../../../types-and-hooks";

interface SystemInputProcessedWorkflowStepDataProps {
  data: SystemInputProcessedWorkflowStepData;
}

function SystemInputProcessedWorkflowStepDataStep({
  data,
}: SystemInputProcessedWorkflowStepDataProps) {
  return (
    <Box mt={3}>
      {data.systemAttributes.length > 0 && (
        <Box mb={3}>
          <Text mb={2} variant="h3">
            System Attributes
          </Text>
          {data.systemAttributes.map((attr) => (
            <Text mb={1}>
              {attr.name}: {attr.value}
            </Text>
          ))}
        </Box>
      )}
      {data.calculatedAttributes.length > 0 && (
        <Box mb={3}>
          <Text mb={2} variant="h3">
            Calculated Attributes
          </Text>
          {data.calculatedAttributes.map((attr) => (
            <Text mb={1}>
              {attr.name}: {attr.value}
            </Text>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default SystemInputProcessedWorkflowStepDataStep;
