import React from "react";
import { Box, Text } from "@11fsfoundry/figloo";

function GenerateRepaymentScheduleProcessedWorkflowStepDataStep() {
  return (
    <Box mt={3}>
      <Text mb={2} variant="h3">
        Payments schedule generated
      </Text>
    </Box>
  );
}

export default GenerateRepaymentScheduleProcessedWorkflowStepDataStep;
